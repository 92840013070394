<template>
  <div>
    <van-cell-group title="填写采购单">
      <van-field label="物品名称" placeholder="请选择物品" v-model="model.BODY.NAME" />
      <van-field v-model="model.BODY.CNT" type="digit" label="数量" placeholder="请输入数量" />
      <van-field v-model="model.BODY.AMOUNT" type="digit" label="金额" placeholder="请输入金额" />
      <van-field v-model="model.DESCRIPTION" type="textarea" label="备注" :rows="2" />
    </van-cell-group>
    <div style="margin: 15px">
      <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading" loading-text="正在提交。。。">确认提交</van-button>
      <br />
      <van-button plain type="default" size="large" icon="cross" block to="/wfl">取消返回</van-button>
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
export default {
  name: "new",
  data() {
    return {
      model: {
        CONF_SN:"mat_purchase",
        BODY:{CNT:1}
      },
      ret:{
        loading:false
      }
    }
  },
  created() {
  },
  methods:{
    submit(){
      let self=this;
      self.ret.loading=true;
      this.whale.remote.getResult({
        url:"/api/Mobile/WFL/ActApi/Save",
        data:this.model,
        finally(){
          self.ret.loading=false;
        },
        completed(){
          self.$dialog.alert({
            title:"完成",
            message:"记录已成功提交！",
            theme:"round",
          }).then(()=>{self.$router.go(-1);})
        }
      })
    }
  }
}
</script>

